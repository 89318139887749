<script src="https://cdnjs.cloudflare.com/ajax/libs/velocity/1.2.3/velocity.min.js"></script>
<template>

  <div class="app-wrapper">

    <div class="header">
        <div class="logo">
            <img src="../assets/img/logo.svg" alt="Stabit">
        </div>
        <div class="slogan">
            <span>Quocunque Jeceris Stabit.</span>
        </div>
      </div> 

      <!--<div class="logo_show" v-if="logo_show"    >
        <img :src="getLogoSrc" /> 
      </div>-->

    
    <div class="app-content">
      <div class="item_front">

      <transition v-on:enter="enter" v-bind:css="false">
        <lineBar v-bind:data_currentlys="data_currentlys" coin="BTCUSDT" />
      </transition>
      </div>

      <div class="item_front">
          <transition v-on:enter="enter" v-bind:css="false">
            <lineBar v-bind:data_currentlys="data_currentlys" coin="ETHUSDT" />
          </transition> 
      </div>
 <div class="item_front">
      <transition v-on:enter="enter" v-bind:css="false">
        <lineBar v-bind:data_currentlys="data_currentlys" coin="BNBUSDT" />
      </transition> </div>
 <div class="item_front">
      <transition v-on:enter="enter" v-bind:css="false">
        <lineBar v-bind:data_currentlys="data_currentlys" coin="SOLUSDT" />
      </transition> </div>
 <div class="item_front">
      <transition v-on:enter="enter" v-bind:css="false">
        <lineBar v-bind:data_currentlys="data_currentlys" coin="ADAUSDT" />
      </transition> </div>
 <div class="item_front">
      <transition v-on:enter="enter" v-bind:css="false">
        <lineBar v-bind:data_currentlys="data_currentlys" coin="XRPUSDT" />
      </transition> </div>
 <div class="item_front">
      <transition v-on:enter="enter" v-bind:css="false">
        <lineBar v-bind:data_currentlys="data_currentlys" coin="DOTUSDT" />
      </transition> </div>
       <div class="item_front">
      <transition v-on:enter="enter" v-bind:css="false">
        <lineBar v-bind:data_currentlys="data_currentlys" coin="DOGEUSDT" />
      </transition> </div>
       <div class="item_front">
      <transition v-on:enter="enter" v-bind:css="false">
        <lineBar v-bind:data_currentlys="data_currentlys" coin="AVAXUSDT" />
      </transition> </div>
       <div class="item_front">
      <transition v-on:enter="enter" v-bind:css="false">
        <lineBar v-bind:data_currentlys="data_currentlys" coin="SHIBUSDT" />
      </transition> </div>
    </div>

<footer class="footer-coin">
    <marquee scrollamount="20" direction=left>

    <div class="footer-coin-wrapper">

      <div v-for="item in filteredToken" :key="item.sys"  >
       <div class="footer-coin-item">

        <div class="info">
                        <div class="icon">
                          <img :src="item.image" /> 
                        </div>
                        <div class="name">
                            <span class="symbol">{{ item.symbol_short }}</span>
                        </div>
                    </div>
                    <div class="values">
                        <div class="price">
                            <span class="pair">$</span>
                            <span class="live">{{ item.price }}</span>
                        </div>   
                        <span  :class="{ item_percent_up:  item.diffrent24 >= 0,   item_percent_down:  item.diffrent24 < 0}"  > {{ item.diffrent24 }}%</span>               
                  </div>
                  
          
              
       

        </div>
      </div>

    </div>

    </marquee >
  
    </footer>
   
  </div>
</template>

<script>
import lineBar from "./lineBar.vue";



export default {
  name: "App",
  data: function () {
    return {
      connection: null,
      counterInterval: null,
      logo_show:true,
      counter: 0,
      counter_change:0,
      mod:0,
      logo_random_str:"asd",
      AED_price: 0,
      data_currentlys: {
        BTCUSDT: {
          symbol: "-",
          price: "-",
          diffrent24: "-",
          type: "main",
          symbol_desc: "Bitcoin",
          price_prefix: "USDT",
          symbol_short: "BTC",
          image: require("../assets/img/BTC.svg"),
          active_show: "BTCUSDT",
          show: true,
        },
        ETHUSDT: {
          symbol: "-",
          price: "-",
          diffrent24: "-",
          type: "main",
          symbol_desc: "Ethereum",
          price_prefix: "USDT",
          symbol_short: "ETH",
          image: require("../assets/img/ETH.svg"),
          active_show: "ETHUSDT",
          show: true,
        },
        BNBUSDT: {
          symbol: "-",
          price: "-",
          diffrent24: "-",
          type: "main",
          symbol_desc: "Binance",
          price_prefix: "USDT",
          symbol_short: "BNB",
          image: require("../assets/img/BNB.svg"),
          active_show: "BNBUSDT",
          show: true,
        },
        SOLUSDT: {
          symbol: "-",
          price: "-",
          diffrent24: "-",
          type: "main",
          symbol_desc: "Solana",
          price_prefix: "USDT",
          symbol_short: "SOL",
          image: require("../assets/img/SOL.svg"),
          active_show: "SOLUSDT",
          show: true,
        },
        ADAUSDT: {
          symbol: "-",
          price: "-",
          diffrent24: "-",
          type: "main",
           symbol_desc: "Cardano",
          price_prefix: "USDT",
          symbol_short: "ADA",
          image: require("../assets/img/ADA.svg"),
          active_show: "ADAUSDT",
          show: true,
        },
        XRPUSDT: {
          symbol: "-",
          price: "-",
          diffrent24: "-",
          type: "main",
          price_prefix: "USDT",
             symbol_desc: "XRP",
          symbol_short: "XRP",
          image: require("../assets/img/XRP.svg"),
          active_show: "XRPUSDT",
          show: true,
        },
        DOTUSDT: {
          symbol: "-",
          price: "-",
          diffrent24: "-",
          type: "main",
          price_prefix: "USDT",
             symbol_desc: "Polkadot",
          symbol_short: "DOT",
          image: require("../assets/img/DOT.svg"),
          active_show: "DOTUSDT",
          show: true,
        },
        DOGEUSDT: {
          symbol: "-",
          price: "-",
          diffrent24: "-",
          type: "main",
          price_prefix: "USDT",
             symbol_desc: "Dogecoin",
          symbol_short: "DOGE",
          image: require("../assets/img/DOGE.svg"),
          active_show: "DOGEUSDT",
          show: true,
        },
        AVAXUSDT: {
          symbol: "-",
          price: "-",
          diffrent24: "-",
          type: "main",
          price_prefix: "USDT",
             symbol_desc: "Avalanche",
          symbol_short: "AVAX",
          image: require("../assets/img/AVAX.svg"),
          active_show: "AVAXUSDT",
          show: true,
        },
        SHIBUSDT: {
          symbol: "-",
          price: "-",
          diffrent24: "-",
          type: "main",
          price_prefix: "USDT",
          symbol_short: "SHIB",
             symbol_desc: "Shiba Inu",
          image: require("../assets/img/SHIB.svg"),
          active_show: "SHIBUSDT",
          show: true,
        },
          LINKBTC: { symbol: "-", price: "-", diffrent24: "-", type: "bottom",   type: "main",
          price_prefix: "BTC", symbol_short: "LINK",   symbol_desc: "Chainlink",
           image: require("../assets/img/LINK.svg") },



 BTCAED: {
          symbol: "-",
          price: "-",
          diffrent24: "-",
          type: "main",
          symbol_desc: "",
          price_prefix: "AED",  symbol_desc: "Bitcoin",
          symbol_short: "BTC",
          image: require("../assets/img/BTC.svg"),
          active_show: "BTCAED",
          show: true,
        },

         ETHAED: {
          symbol: "-",
          price: "-",
          diffrent24: "-",
          type: "main",
          symbol_desc: "",
          price_prefix: "AED",  symbol_desc: "Ethereum",
          symbol_short: "ETH",
          image: require("../assets/img/ETH.svg"),
          active_show: "ETHAED",
          show: true,
        },
        BNBAED: {
          symbol: "-",
          price: "-",
          diffrent24: "-",
          type: "main",
          symbol_desc: "",
          price_prefix: "AED",  symbol_desc: "Binance",
          symbol_short: "BNB",
          image: require("../assets/img/BNB.svg"),
          active_show: "BNBAED",
          show: true,
        },
        SOLAED: {
          symbol: "-",
          price: "-",
          diffrent24: "-",
          type: "main",
          symbol_desc: "",
          price_prefix: "AED",
          symbol_short: "SOL",symbol_desc: "Solana",
          image: require("../assets/img/SOL.svg"),
          active_show: "SOLAED",
          show: true,
        },
        ADAAED: {
          symbol: "-",
          price: "-",
          diffrent24: "-",
          type: "main",
          price_prefix: "AED", symbol_desc: "Cardano",
          symbol_short: "ADA",
          image: require("../assets/img/ADA.svg"),
          active_show: "ADAAED",
          show: true,
        },
        XRPAED: {
          symbol: "-",
          price: "-",
          diffrent24: "-",
          type: "main",
          price_prefix: "AED", 
          symbol_short: "XRP", symbol_desc: "XRP",
          image: require("../assets/img/XRP.svg"),
          active_show: "XRPAED",
          show: true,
        },
        DOTAED: {
          symbol: "-",
          price: "-",
          diffrent24: "-",
          type: "main",
          price_prefix: "AED", symbol_desc: "Polkadot",
          symbol_short: "DOT",
          image: require("../assets/img/DOT.svg"),
          active_show: "DOTAED",
          show: true,
        },
        DOGEAED: {
          symbol: "-",
          price: "-",
          diffrent24: "-",
          type: "main",
          price_prefix: "AED",
          symbol_short: "DOGE",symbol_desc: "Dogecoin",
          image: require("../assets/img/DOGE.svg"),
          active_show: "DOGEAED",
          show: true,
        },
        AVAXAED: {
          symbol: "-",
          price: "-",
          diffrent24: "-",
          type: "main",
          price_prefix: "AED",
          symbol_short: "AVAX", symbol_desc: "Avalanche",
          image: require("../assets/img/AVAX.svg"),
          active_show: "AVAXAED",
          show: true,
        },
        SHIBAED: {
          symbol: "-",
          price: "-",
          diffrent24: "-",
          type: "main",
          price_prefix: "AED",
          symbol_short: "SHIB",  symbol_desc: "Shiba Inu",
          image: require("../assets/img/SHIB.svg"),
          active_show: "SHIBAED",
          show: true,
        },

        BTCUSDT: {
          symbol: "-",
          price: "-",
          diffrent24: "-",
          type: "main",
          symbol_desc: "",
          price_prefix: "USDT",
          symbol_short: "BTC", symbol_desc: "Bitcoin",
          image: require("../assets/img/BTC.svg"),
          active_show: "BTCUSDT",
          show: true,
        },

         ETHBTC: {
          symbol: "-",
          price: "-",
          diffrent24: "-",
          type: "main",
          symbol_desc: "",
          price_prefix: "BTC", symbol_desc: "Ethereum",
          symbol_short: "ETH",
          image: require("../assets/img/ETH.svg"),
          active_show: "ETHBTC",
          show: true,
        },
        BNBBTC: {
          symbol: "-",
          price: "-",
          diffrent24: "-",
          type: "main",
          symbol_desc: "",
          price_prefix: "BTC", symbol_desc: "Binance",
          symbol_short: "BNB",
          image: require("../assets/img/BNB.svg"),
          active_show: "BNBBTC",
          show: true,
        },
        SOLBTC: {
          symbol: "-",
          price: "-",
          diffrent24: "-",
          type: "main",
          symbol_desc: "",
          price_prefix: "BTC", symbol_desc: "Solana",
          symbol_short: "SOL",
          image: require("../assets/img/SOL.svg"),
          active_show: "SOLBTC",
          show: true,
        },
        ADABTC: {
          symbol: "-",
          price: "-",
          diffrent24: "-",
          type: "main",
          price_prefix: "BTC", symbol_desc: "Cardano",
          symbol_short: "ADA",
          image: require("../assets/img/ADA.svg"),
          active_show: "ADABTC",
          show: true,
        },
        XRPBTC: {
          symbol: "-",
          price: "-",
          diffrent24: "-",
          type: "main",
          price_prefix: "BTC", symbol_desc: "XRP",
          symbol_short: "XRP",
          image: require("../assets/img/XRP.svg"),
          active_show: "XRPBTC",
          show: true,
        },
        DOTBTC: {
          symbol: "-",
          price: "-",
          diffrent24: "-",
          type: "main",
          price_prefix: "BTC",   symbol_desc: "Polkadot",
          symbol_short: "DOT",
          image: require("../assets/img/DOT.svg"),
          active_show: "DOTBTC",
          show: true,
        },
        DOGEBTC: {
          symbol: "-",
          price: "-",
          diffrent24: "-",
          type: "main",
          price_prefix: "BTC",
          symbol_short: "DOGE", symbol_desc: "Dogecoin",
          image: require("../assets/img/DOGE.svg"),
          active_show: "DOGEBTC",
          show: true,
        },
        AVAXBTC: {
          symbol: "-",
          price: "-",
          diffrent24: "-",
          type: "main",
          price_prefix: "BTC",
          symbol_short: "AVAX",   symbol_desc: "Avalanche",
          image: require("../assets/img/AVAX.svg"),
          active_show: "AVAXBTC",
          show: true,
        },
        SHIBBTC: {
          symbol: "-",
          price: "-",
          diffrent24: "-",
          type: "main", symbol_desc: "Shiba Inu",
          price_prefix: "BTC",
          symbol_short: "SHIB",
          image: require("../assets/img/SHIB.svg"),
          active_show: "SHIBBTC",
          show: true,
        },


        LUNAUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "LUNA",  image: require("../assets/img/LUNA.svg") },
        LTCUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "LTC",  image: require("../assets/img/LTC.svg")    },
        UNIUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom", symbol_short: "UNI",  image: require("../assets/img/UNI.svg")        },
        MATICUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom", symbol_short: "MATIC",  image: require("../assets/img/MATIC.svg") },
        LINKUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom", symbol_short: "LINK",  image: require("../assets/img/LINK.svg") },
        BCHUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom", symbol_short: "BCH",  image: require("../assets/img/BCH.svg") },
        ALGOUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom", symbol_short: "ALGO",  image: require("../assets/img/ALGO.svg") },
        TRXUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom", symbol_short: "TRX",  image: require("../assets/img/TRX.svg") },
        XLMUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom", symbol_short: "XLM",  image: require("../assets/img/XLM.svg") },
        MANAUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "MANA",  image: require("../assets/img/MANA.svg")},

        ATOMUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "ATOM",  image: require("../assets/img/atom.svg") },
        HBARUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "HBAR",  image: require("../assets/img/hbar.png") },
        ETCUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "ETC",  image: require("../assets/img/etc.svg") },
        THETAUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "THETA",  image: require("../assets/img/theta.svg") },
        FTMUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "FTM",  image: require("../assets/img/ftm.svg") },
       
        XTZUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "XTZ",  image: require("../assets/img/xtz.svg") },

        XMRUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "XMR",  image: require("../assets/img/xmr.svg") },

        EOSUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "EOS",  image: require("../assets/img/EOS.svg") },

        IOTAUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "IOTA",  image: require("../assets/img/iota.svg") },

        STXUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "STX",  image: require("../assets/img/stx.svg") },

        AAVEUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "AAVE",  image: require("../assets/img/aave.svg") },

        MKRUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "MKR",  image: require("../assets/img/mkr.svg") },

        ONEUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "ONE",  image: require("../assets/img/one.svg") },

        ZECUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "ZEC",  image: require("../assets/img/zec.svg") },

        ENJUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "ENJ",  image: require("../assets/img/enj.svg") },

        NEOUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "NEO",  image: require("../assets/img/NEO.svg") },

        WAVESUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "WAVES",  image: require("../assets/img/waves.svg") },

        BATUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "BAT",  image: require("../assets/img/bat.svg") },

        CHZUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "CHZ",  image: require("../assets/img/chz.svg") },

        CRVUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "CRV",  image: require("../assets/img/CRV.svg") },

        HOTUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "HOT",  image: require("../assets/img/hot.svg") },

        DASHUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "DASH",  image: require("../assets/img/dash.svg") },

        XEMUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "XEM",  image: require("../assets/img/xem.svg") },

        TFUELUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "TFUEL",  image: require("../assets/img/tfuel.svg") },

        "1INCHUSDT": { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "1INCH",  image: require("../assets/img/1inch.svg") },

        QTUMUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "QTUM",  image: require("../assets/img/qtum.svg") },

        RVNUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "RVN",  image: require("../assets/img/rvn.svg") },

        OMGUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "OMG",  image: require("../assets/img/OMG.svg") },

        ANKRUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "ANKR",  image: require("../assets/img/ANKR.svg") },

        ZENUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "ZEN",  image: require("../assets/img/ZEN.svg") },

        BNTUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "BNT",  image: require("../assets/img/BNT.svg") },

        YFIUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "YFI",  image: require("../assets/img/yfi.svg") },

        YFIIUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "YFII",  image: require("../assets/img/YFII.svg") },

        ZILUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "ZIL",  image: require("../assets/img/zil.svg") },

        SUSHIUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "SUSHI",  image: require("../assets/img/SUSHI.svg") },
        
        ONTUSDT: { symbol: "-", price: "-", diffrent24: "-", type: "bottom" , symbol_short: "ONT",  image: require("../assets/img/ont.svg") },
      

      },
      data_loading: [],
      val: {},
      show: false,
    };
  },
  components: {
    lineBar 
  },
  computed: {
    filteredToken () {
        const self = this;
        return Object.values(self.data_currentlys).filter(function(game) {
          return "bottom" === game.type;
        });
      
    },

      getLogoSrc () {
       return require('../assets/img/Loop.gif') + '?' + Math.random()
      
    }
},
  methods: {
    enter: function (el, done) {
      Velocity(el, { rotateX: "360deg" });
    },

    change_animate: function (mod, coin, coin2, coin3, order) {
      let vm = this;
     
     // 15 sn USDT
      if (mod <= 15 + order) {
        vm.data_currentlys[coin].active_show = coin;

       // 15 sn BTC
      } else if ((mod > 25 + order) & (mod <= 50 + order)) {
                vm.data_currentlys[coin].active_show = coin2;

        if (mod == 26 + order) {
          vm.data_currentlys[coin].show = false;
          setTimeout(() => {  vm.data_currentlys[coin].show = true;    });
        }
       
        // 15 sn Aed
      } else if ((mod > 50 + order) & (mod <= 75 + order)) {
        if (mod == 51 + order) {
          vm.data_currentlys[coin].show = false;
          setTimeout(() => {  vm.data_currentlys[coin].show = true; }, 0.1);
        }
        vm.data_currentlys[coin].active_show = coin3;
      }
       // 15 sn USDT
       else if ((mod > 75 + order) & (mod <= 100 + order)) {
        if (mod == 76 + order) {
          vm.data_currentlys[coin].show = false;
          setTimeout(() => {  vm.data_currentlys[coin].show = true; }, 0.1);
        }
        vm.data_currentlys[coin].active_show = coin;
      }
        // 15 sn BTC
        else if ((mod > 100 + order) & (mod <= 125 + order)) {
        if (mod == 101 + order) {
          vm.data_currentlys[coin].show = false;
          setTimeout(() => {  vm.data_currentlys[coin].show = true; }, 0.1);
        }
        vm.data_currentlys[coin].active_show = coin2;
      }// 15 sn Aed
       else if ((mod > 125 + order) & (mod <= 150 + order)) {
        if (mod == 126 + order) {
          vm.data_currentlys[coin].show = false;
          setTimeout(() => {  vm.data_currentlys[coin].show = true; }, 0.1);
        }
        vm.data_currentlys[coin].active_show = coin3;
      }


         // 15 sn USDT
       else if ((mod > 150 + order) & (mod <= 175 + order)) {
        if (mod == 151 + order) {
          vm.data_currentlys[coin].show = false;
          setTimeout(() => {  vm.data_currentlys[coin].show = true; }, 0.1);
        }
        vm.data_currentlys[coin].active_show = coin;
      }
        // 15 sn BTC
        else if ((mod > 175 + order) & (mod <= 200 + order)) {
        if (mod == 176 + order) {
          vm.data_currentlys[coin].show = false;
          setTimeout(() => {  vm.data_currentlys[coin].show = true; }, 0.1);
        }
        vm.data_currentlys[coin].active_show = coin2;
      }// 15 sn Aed
       else if ((mod > 225 + order) & (mod <= 250 + order)) {
        if (mod == 226 + order) {
          vm.data_currentlys[coin].show = false;
          setTimeout(() => {  vm.data_currentlys[coin].show = true; }, 0.1);
        }
        vm.data_currentlys[coin].active_show = coin3;
      }


         // 15 sn USDT
       else if ((mod > 250 + order) & (mod <= 275 + order)) {
        if (mod == 251 + order) {
          vm.data_currentlys[coin].show = false;
          setTimeout(() => {  vm.data_currentlys[coin].show = true; }, 0.1);
        }
        vm.data_currentlys[coin].active_show = coin;
      }
        // 15 sn BTC
        else if ((mod > 275 + order) & (mod <= 300 + order)) {
        if (mod == 276 + order) {
          vm.data_currentlys[coin].show = false;
          setTimeout(() => {  vm.data_currentlys[coin].show = true; }, 0.1);
        }
        vm.data_currentlys[coin].active_show = coin2;
      }// 15 sn Aed
       else if ((mod > 300 + order) & (mod <= 325 + order)) {
        if (mod == 301 + order) {
          vm.data_currentlys[coin].show = false;
          setTimeout(() => {  vm.data_currentlys[coin].show = true; }, 0.1);
        }
        vm.data_currentlys[coin].active_show = coin3;
      }
      
      if ( (mod >= 0 ) & (mod <= 8 )) {
         vm.logo_show=true;
         vm.logo_random_str=Math.floor(Math.random() * 1000);
      }else {
         vm.logo_show=false;
      }
    },
  },
  created: function () {
    let vm = this;
    var coins = "";

    Object.keys(this.data_currentlys).forEach((val) => {
      let key = val;
      coins += key.toLowerCase() + "@ticker/";
    });

    // console.log(coins.substring(0, coins.length - 1));

    this.connection = new WebSocket(
      "wss://stream.binance.com:9443/ws/" + coins.substring(0, coins.length - 1) //   "wss://stream.binance.com:9443/ws/btcusdt@ticker/solusdt@ticker"
    );

    this.connection.onmessage = function (event) {
      vm.val = JSON.parse(event.data);

      if (vm.val.s == "BTCUSDT") {
        vm.data_currentlys["BTCAED"].price =(parseFloat(vm.val.c) * vm.AED_price).toFixed(2);
        vm.data_currentlys["BTCAED"].diffrent24 = parseFloat(vm.val.P).toFixed(2);
      }
      if (vm.val.s == "ETHUSDT") {
        vm.data_currentlys["ETHAED"].price =(parseFloat(vm.val.c) * vm.AED_price).toFixed(2);
        vm.data_currentlys["ETHAED"].diffrent24 = parseFloat(vm.val.P).toFixed(2);
      }
       if (vm.val.s == "BNBUSDT") {
        vm.data_currentlys["BNBAED"].price =(parseFloat(vm.val.c) * vm.AED_price).toFixed(2);
        vm.data_currentlys["BNBAED"].diffrent24 = parseFloat(vm.val.P).toFixed(2);
      }
       if (vm.val.s == "SOLUSDT") {
        vm.data_currentlys["SOLAED"].price =(parseFloat(vm.val.c) * vm.AED_price).toFixed(2);
        vm.data_currentlys["SOLAED"].diffrent24 = parseFloat(vm.val.P).toFixed(2);
      }
       if (vm.val.s == "ADAUSDT") {
        vm.data_currentlys["ADAAED"].price =(parseFloat(vm.val.c) * vm.AED_price).toFixed(2);
        vm.data_currentlys["ADAAED"].diffrent24 = parseFloat(vm.val.P).toFixed(2);
      }
       if (vm.val.s == "XRPUSDT") {
        vm.data_currentlys["XRPAED"].price =(parseFloat(vm.val.c) * vm.AED_price).toFixed(2);
        vm.data_currentlys["XRPAED"].diffrent24 = parseFloat(vm.val.P).toFixed(2);
      }
       if (vm.val.s == "DOTUSDT") {
        vm.data_currentlys["DOTAED"].price =(parseFloat(vm.val.c) * vm.AED_price).toFixed(2);
        vm.data_currentlys["DOTAED"].diffrent24 = parseFloat(vm.val.P).toFixed(2);
      }
       if (vm.val.s == "DOGEUSDT") {
        vm.data_currentlys["DOGEAED"].price =(parseFloat(vm.val.c) * vm.AED_price).toFixed(2);
        vm.data_currentlys["DOGEAED"].diffrent24 = parseFloat(vm.val.P).toFixed(2);
      }

       if (vm.val.s == "AVAXUSDT") {
        vm.data_currentlys["AVAXAED"].price =(parseFloat(vm.val.c) * vm.AED_price).toFixed(2);
        vm.data_currentlys["AVAXAED"].diffrent24 = parseFloat(vm.val.P).toFixed(2);
      }

       if (vm.val.s == "SHIBUSDT") {
        vm.data_currentlys["SHIBAED"].price =(parseFloat(vm.val.c) * vm.AED_price).toFixed(6);
        vm.data_currentlys["SHIBAED"].diffrent24 = parseFloat(vm.val.P).toFixed(2);
      }

      vm.data_currentlys[vm.val.s].price = parseFloat(vm.val.c);
      vm.data_currentlys[vm.val.s].diffrent24 = parseFloat(vm.val.P).toFixed(2);
    };

   
    vm.AED_price = 3.67;
  

 
    this.counterInterval = setInterval(
      function () {
         vm.mod = vm.counter % 325;
        this.change_animate(vm.mod, "BTCUSDT", "BTCUSDT", "BTCAED", 0);
        this.change_animate(vm.mod, "ETHUSDT", "ETHBTC", "ETHAED", 1);
        this.change_animate(vm.mod, "BNBUSDT", "BNBBTC", "BNBAED", 2);
        this.change_animate(vm.mod, "SOLUSDT", "SOLBTC", "SOLAED", 3);
        this.change_animate(vm.mod, "ADAUSDT", "ADABTC", "ADAAED", 4);
        this.change_animate(vm.mod, "XRPUSDT", "XRPBTC", "XRPAED", 5);
        this.change_animate(vm.mod, "DOTUSDT", "DOTBTC", "DOTAED", 6);
        this.change_animate(vm.mod, "DOGEUSDT", "DOGEBTC", "DOGEAED", 7);
        this.change_animate(vm.mod, "AVAXUSDT", "AVAXBTC", "AVAXAED", 8);
        this.change_animate(vm.mod, "SHIBUSDT", "LINKBTC", "SHIBAED", 9);
        


      /*  if(vm.counter_change%4==1){
          vm.logo_show=false;
        }else{
           vm.logo_show=false;
        }

        if(vm.mod==0){
           vm.counter_change = vm.counter_change + 1;
        }*/
        vm.counter = vm.counter + 1;
      }.bind(this),
      1000
    );
  },

  destoyed: function () {
    clearInterval(this.counterInterval);
   
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.logo>img{
  height: 4vh;
  margin-bottom: 3vh;
  margin-left:1vh;
}





.logo_show{
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black; /* Black background with opacity */
  z-index: 99999; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */ 
}

.logo_show > img {
  text-align: center;
  margin:0 auto;
  height: 105vh;
  padding-top: 0vh;
}


</style>
