<template>
  <div id="app">
    <HomeScreen/>
  </div>
</template>
<script>
import HomeScreen from './components/HomeScreen.vue'


export default {
  title: "Cryptocurrency",
  name: 'App',
  components: {
    HomeScreen
   
  }
}
</script>

<style>

</style>
