<script src="https://cdnjs.cloudflare.com/ajax/libs/velocity/1.2.3/velocity.min.js"></script>
<template>

        <div class="coin-item" v-if="data_currentlys[coin].show">

            <div class="info">
              <div class="icon">
                <img :src="require(`@/assets/img/${data_currentlys[data_currentlys[coin].active_show].symbol_short}.svg`)">
              </div>
              <div class="name">
                    <span class="symbol">{{ data_currentlys[data_currentlys[coin].active_show].symbol_short  }}</span>
                    <span class="full">{{ data_currentlys[data_currentlys[coin].active_show].symbol_desc }}</span>
                </div>
            </div>

            <div class="values">
                <div class="price">
                    <span class="pair"></span>
                    <span class="live">{{    data_currentlys[data_currentlys[coin].active_show].price    }} ({{    data_currentlys[data_currentlys[coin].active_show].price_prefix }})</span>
                </div>
                <span class="change down" v-bind:class="{ item_percent_up:  data_currentlys[data_currentlys[coin].active_show].diffrent24 >= 0, item_percent_down: data_currentlys[data_currentlys[coin].active_show] .diffrent24 < 0, }">{{data_currentlys[data_currentlys[coin].active_show].diffrent24}}%</span>
            </div>

        </div>

     
</template>

<script>
export default {
    name:"Bar",
   props: ['data_currentlys','coin'],
    enter: function (el, done) {
      Velocity(el, { rotateX: "360deg" });
    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
